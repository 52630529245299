import React, {useState} from "react";
import dayjs from "dayjs";
import DataLayout from "../../../components/DataLayout";
import OptFilter from "../../../components/OperatorFilter";
import {apiList} from "../../../utils/api-list";
import {Tag} from 'antd';

const MerchantCus = () => {
    const [filters, setFilters] = useState({});
    const [tableFilter, setTableFilter] = useState();
    const sorter = {sorter: true, sortDirections: ["descend", "ascend"]};

    const columns = [
        // { title: "Store UID", key: "id", dataIndex: "store_uid" },
        {title: "Store Name", dataIndex: "store_name", key: "name", render: (name) => name ? name : 'N/A'},
        {
            title: "Store ID",
            key: "store_strid",
            dataIndex: "store_strid",
            render: (store_strid) => store_strid ? store_strid : 'N/A'
        },
        {title: "Name", dataIndex: "name", key: "name"},
        {title: "Phone", dataIndex: "phone_number", key: "phone_number"},
        {
            title: "Balance",
            key: "balance",
            dataIndex: "balance",
            render: (t, r) => {
                let color = r.balance < 0 ? "#f50" : "#108ee9";
                return (
                    <Tag color={color}>
                        {r.balance.toLocaleString()}
                    </Tag>
                )
            },
            filterDropdown: ({confirm, clearFilters}) => (
                <OptFilter
                    tableFilter={tableFilter}
                    setTableFilter={setTableFilter}
                    confirm={confirm}
                    clearFilters={clearFilters}
                    setFilters={setFilters}
                    operator="operator"
                    to="to_balance"
                    from="from_balance"
                />
            ),
            ...sorter,
        },
        {
            title: "Payback Date",
            key: "payback_date",
            dataIndex: "payback_date",
            render: (text, {payback_date}) =>
                payback_date ? dayjs(payback_date).format("YYYY-MM-DD") : 'N/A',
            ...sorter,
        },
        {title: "Created", dataIndex: "created_at", key: "created_at", ...sorter},
    ];

    const filterParams = [
        {id: 1, type: "TEXT", label: "Store UID", name: "store_uid"},
        {id: 2, type: "TEXT", label: "Store ID", name: "store_strid"},
        {id: 3, type: "TEXT", label: "Store Name", name: "store_name"},
        {id: 4, type: "DATERANGE", label: "Date Range", name: "date_range"},
        {id: 5, type: "TEXT", label: "Customer Name", name: "customer_name"},
        {id: 6, type: "TEXT", label: "Customer Phone", name: "customer_phone"},
        {id: 7, type: "DATE", label: "Payback Date", name: "date"},
    ];

    //render
    return (
        <DataLayout
            title="Merchant customers List"
            columns={columns}
            filterParams={filterParams}
            filters={filters}
            setFilters={setFilters}
            setTableFilter={setTableFilter}
            apiEndpoint={apiList?.merchantCustomer}
            responseSlug="merchant_customer_list"
            dateFilterName="payback_date"
        />
    );
};

export default MerchantCus;
