import React, {useState} from "react";
import DataLayout from "../../../components/DataLayout";
import OptFilter from "../../../components/OperatorFilter";
import {apiList} from "../../../utils/api-list";
import {subs_prov_drp, sts, sslcom_opts} from "./subscription.helper";
import ModalButton from "../../../components/ModalButton";

const Subscription = () => {
    const [filters, setFilters] = useState({});
    const [tableFilter, setTableFilter] = useState();
    const sorter = {sorter: true, sortDirections: ["descend", "ascend"]};
    const columns = [
        {
            title: "Store Name", 
            dataIndex: "store_name", 
            key: "name", render: (name) => name ? name : 'N/A',
            ...sorter
        },
        {
            title: "Store ID",
            key: "store_strid",
            dataIndex: "store_strid",
            render: (store_strid) => store_strid ? store_strid : 'N/A'
        },
        {
            title: "Msisdn",
            key: "msisdn",
            dataIndex: "msisdn",
            render: (msisdn) => msisdn ? msisdn : 'N/A',
            ...sorter
        },
        {
            title: "Subscription Provider",
            key: "subscription_provider_title",
            dataIndex: "subscription_provider_title",
            render: (subscription_provider_title) => subscription_provider_title ? subscription_provider_title : 'N/A',
            ...sorter
        },
        {
            title: "Subscription Package",
            key: "subscription_package_title",
            dataIndex: "subscription_package_title",
            render: (subscription_package_title) => subscription_package_title ? subscription_package_title : 'N/A',
            ...sorter
        },
        {
            title: "Amount",
            key: "amount",
            dataIndex: "amount",
            filterDropdown: ({confirm, clearFilters}) => (
                <OptFilter
                    tableFilter={tableFilter}
                    setTableFilter={setTableFilter}
                    confirm={confirm}
                    clearFilters={clearFilters}
                    setFilters={setFilters}
                    operator="operator"
                    to="to_amount"
                    from="from_amount"
                />
            ),
            ...sorter,
        },
        {
            title: "Bill Amount",
            key: "bill_amount",
            dataIndex: "bill_amount",
            filterDropdown: ({confirm, clearFilters}) => (
                <OptFilter
                    tableFilter={tableFilter}
                    setTableFilter={setTableFilter}
                    confirm={confirm}
                    clearFilters={clearFilters}
                    setFilters={setFilters}
                    operator="operator"
                    to="to_bill_amount"
                    from="from_bill_amount"
                />
            ),
            ...sorter,
        },
        {
            title: "Status", 
            key: "status_d", 
            dataIndex: "status_title",
        },
        {
            title: "Transaction date", 
            key: "tr_d", 
            dataIndex: "transaction_date",
            ...sorter
        },
        {title: "Created At", key: "c_at", dataIndex: "created_at", ...sorter},
        {
            title: "Details",
            key: "details",
            dataIndex: "details",
            render: (details, data) =>
                <ModalButton
                    name={"Show"}
                    title={"Details"}
                    data={
                        <>
                            <p><strong>Transaction Id: </strong> {data.transaction_id}</p>
                            <p><strong>SSLCommerz Transaction Id: </strong> {data.ssl_commerz_tran_id}</p>
                            <p><strong>Subscription Provider Transaction Id: </strong> {data.subscription_provider_transaction_id}</p>
                            <p><strong>SSLCommerz Status: </strong> {data.ssl_commerz_status_title}</p>
                            <p><strong>Coupon Id: </strong> {data.coupon_id}</p>
                            <p><strong>SMS Reference Id: </strong> {data.sms_reference_id}</p>
                            <p><strong>SMS Sent: </strong> {data.is_sms_sent_title}</p>
                            <p><strong>SMS Sent At: </strong> {data.sms_sent_at}</p>
                            <p><strong>Remarks: </strong> {data.remarks}</p>
                        </>
                    }
                />
        }
    ];

    const filterParams = [
         {id: 1, type: "TEXT", label: "Store Name", name: "store_name"},
         {id: 2, type: "TEXT", label: "Store ID", name: "store_strid"},
         {id: 3, type: "TEXT", label: "Msisdn", name: "msisdn"},
          {
            id: 4,
            type: "SELECT",
            label: "Subscription Provider",
            name: "subscription_provider_id",
            options: subs_prov_drp,
        },
        {
            id: 5, 
            type: "SELECT", 
            label: "Status", 
            name: "status", 
            options: sts
        },
        {id: 6, type: "DATE", label: "Transaction Date", name: "date"},
        {
            id: 7,
            type: "SELECT",
            label: "SSLCommerz status",
            name: "ssl_commerz_status",
            options: sslcom_opts,
        },
        {id: 4, type: "DATERANGE", label: "Date Range", name: "date_range"},
    ];

    //render
    return (
        <DataLayout
            title="Subscription Transaction List"
            columns={columns}
            filterParams={filterParams}
            filters={filters}
            setFilters={setFilters}
            setTableFilter={setTableFilter}
            apiEndpoint={apiList?.subscriptionTransaction}
            responseSlug="subscription_transaction_list"
            dateFilterName="transaction_date"
        />
    );
};

export default Subscription;
