import React, {useState} from "react";
import DataLayout from "../../../components/DataLayout";
import {apiList} from "../../../utils/api-list";
import OptFilter from "../../../components/OperatorFilter";
import ModalButton from "../../../components/ModalButton";
import dayjs from "dayjs";

const status = [
    {key: 0, value: 'pending', title: "Pending"},
    {key: 1, value: 'rejected', title: "Rejected"},
    {key: 2, value: 'waiting_for_review', title: "Waiting For Review"},
    {key: 3, value: 'accepted', title: "Accepted"},
    {key: 4, value: 'paid', title: "Paid"},
    {key: 5, value: 'on_the_way', title: "On The Way"},
    {key: 6, value: 'delivered', title: "Delivered"},
    {key: 7, value: 'cancelled', title: "Cancelled"},
];

const OrderList = () => {
    const [filters, setFilters] = useState({});
    const [tableFilter, setTableFilter] = useState();
    const sorter = {sorter: true, sortDirections: ["descend", "ascend"]};

    const columns = [
        {
            title: "Invoice",
            dataIndex: "invoice_number",
            key: "invoice_number",
            render: (invoice_number) => invoice_number ? invoice_number : 'N/A'
        },
        {
            title: "Store Name",
            key: "store_name",
            dataIndex: "store_name",
            render: (store_name) => store_name ? store_name : 'N/A',
            ...sorter
        },
        {
            title: "Store ID",
            key: "store_strid",
            dataIndex: "store_strid",
            render: (store_strid) => store_strid ? store_strid : 'N/A'
        },
        {
            title: "Customer Name",
            key: "customer_name",
            dataIndex: "customer_name",
            render: (customer_name) => customer_name ? customer_name : 'N/A',
            ...sorter
        },
        {
            title: "Customer Mobile No",
            key: "customer_mobile_no",
            dataIndex: "customer_mobile_no",
            render: (customer_mobile_no) => customer_mobile_no ? customer_mobile_no : 'N/A',
            ...sorter
        },
        {
            title: "Products",
            key: "hl_order_products_count",
            dataIndex: "hl_order_products_count",
            render: (hl_order_products_count) => hl_order_products_count ? hl_order_products_count : 'N/A',
            ...sorter
        },
        {
            title: "Bill Amount",
            key: "bill_amount",
            dataIndex: "bill_amount",
            render: (bill_amount) => bill_amount ? bill_amount : 0,
            filterDropdown: ({confirm, clearFilters}) => (
                <OptFilter
                    tableFilter={tableFilter}
                    setTableFilter={setTableFilter}
                    confirm={confirm}
                    clearFilters={clearFilters}
                    setFilters={setFilters}
                    operator="operator"
                    to="to_bill_amount"
                    from="from_bill_amount"
                />
            ),
            ...sorter
        },
        {
            title: "Due Amount",
            key: "due_amount",
            dataIndex: "due_amount",
            render: (due_amount) => due_amount ? due_amount : 0,
            filterDropdown: ({confirm, clearFilters}) => (
                <OptFilter
                    tableFilter={tableFilter}
                    setTableFilter={setTableFilter}
                    confirm={confirm}
                    clearFilters={clearFilters}
                    setFilters={setFilters}
                    operator="operator"
                    to="to_due_amount"
                    from="from_due_amount"
                />
            ),
            ...sorter
        },
        {
            title: "Status",
            dataIndex: "status_title",
            key: "status_title",
            render: (status_title) => status_title ? status_title : 'N/A'
        },
        {
            title: "Order Date",
            key: "created_at",
            dataIndex: "created_at",
            render: (created_at) => created_at ? dayjs(created_at).format("YYYY-MM-DD HH:mm:ss") : 'N/A', ...sorter
        },
        {
            title: "Details",
            key: "details",
            dataIndex: "details",
            render: (details, data) =>
                <ModalButton
                    name={"Show"}
                    title={"Details"}
                    data={
                        <>
                            <p><strong>Products: </strong>
                                <ol> {data.hl_order_products.map(d => (
                                    <li key={d.id}>{d.quantity}x {d.product_name}</li>))}</ol>
                            </p>
                            <p><strong>Billing Address: </strong> {data.billing_address}</p>
                            <p><strong>Delivery Address: </strong> {data.delivery_address}</p>
                            <p><strong>Delivery Fee: </strong> {data.delivery_fee}</p>
                            <p><strong>Delivery Date: </strong> {data.delivery_date}</p>
                            <p><strong>Delivered At: </strong> {data.delivered_at}</p>
                            <p><strong>Paid At: </strong> {data.paid_at}</p>
                            <p><strong>Transaction ID: </strong> {data.transaction_id}</p>
                            <p><strong>Order Amount: </strong> {data.order_amount}</p>
                            <p><strong>Discount Amount: </strong> {data.discount_amount}</p>
                            <p><strong>Additional Charge: </strong> {data.additional_change}</p>
                            <p><strong>Special Note: </strong> {data.special_note}</p>
                            <p><strong>Rating: </strong> {data.rating}</p>
                        </>
                    }
                />
        }
    ];

    const filterParams = [
        {id: 1, type: "TEXT", label: "Invoice No", name: "invoice_number"},
        {id: 2, type: "TEXT", label: "Customer Name", name: "customer_name"},
        {id: 3, type: "TEXT", label: "Customer Mobile no", name: "customer_mobile_no"},
        {id: 4, type: "SELECT", label: "Status", name: "status", options: status},
        {id: 5, type: "DATERANGE", label: "Order Date Range", name: "created_at"},
        {id: 6, type: "DATERANGE", label: "Delivery Date Range", name: "delivery_date"},
        {id: 7, type: "DATERANGE", label: "Delivered Date Range", name: "delivered_at"},
        {id: 8, type: "DATERANGE", label: "Paid Date Range", name: "paid_at"},
        {id: 9, type: "TEXT", label: "Store ID", name: "store_strid"},
        {id: 10, type: "TEXT", label: "Store Name", name: "store_name"},
    ];

    //render
    return (
        <DataLayout
            title="Order List"
            columns={columns}
            filterParams={filterParams}
            filters={filters}
            setFilters={setFilters}
            setTableFilter={setTableFilter}
            apiEndpoint={apiList?.orderList}
            responseSlug="hyperlocal_order_list"
            dateFilterName="created_at"
        />
    );
};

export default OrderList;
