import {Button, Card, Form, Input, notification} from "antd";
import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {login} from "../../../store/actions";
import {apiList} from "../../../utils/api-list";
import {postData} from "../../../utils/api-service";
import cookies from "js-cookie";
import "./login.scss";

const Login = () => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState();
    const rules = {rules: [{required: true, message: "Required field!"}]};
    const submit = async (values) => {
        setLoading(true);
        const res = await postData({
            query: apiList?.login,
            data: values,
        });
        if (res?.data?.code === 200) {
            const token = res?.data?.data?.access_token;
            cookies?.set("token", token);
            dispatch(login(token));
        } else notification?.error({message: "Wrong email or password!"});
        setLoading(false);
    };

    return (
        <div className="login">
            <Card className="elevated card">
                <h2>Login</h2>
                <Form onFinish={submit} layout="vertical" form={form}>
                    <Form.Item {...rules} label="Email" name="email">
                        <Input placeholder="email" type="email"/>
                    </Form.Item>
                    <Form.Item {...rules} label="Password" name="password">
                        <Input.Password placeholder="password" type="password"/>
                    </Form.Item>
                    <Button
                        loading={loading}
                        htmlType="submit"
                        block
                        type="primary"
                    >
                        Login
                    </Button>
                </Form>
            </Card>
        </div>
    );
};

export default Login;
