import React, {useState} from "react";
import dayjs from "dayjs";
import DataLayout from "../../../components/DataLayout";
import OptFilter from "../../../components/OperatorFilter";
import {apiList} from "../../../utils/api-list";
import ModalButton from "../../../components/ModalButton";

const HaalkhataTransactions = () => {
    const [filters, setFilters] = useState({});
    const [tableFilter, setTableFilter] = useState();
    const sorter = {sorter: true, sortDirections: ["descend", "ascend"]};

    const columns = [
        // { title: "Store UID", key: "id", render: (t, r) => r?.merchant?.store_uid },
        {
            title: "Store Name",
            key: "name",
            dataIndex: "name",
            render: (t, r) => r?.merchant?.store_name ? r?.merchant?.store_name : 'N/A',
        },
        {
            title: "Store ID",
            key: "strid",
            dataIndex: "strid",
            render: (t, r) => r?.merchant?.store_strid ? r?.merchant?.store_strid : 'N/A',
        },
        {title: "Name", key: "name", render: (t, r) => r?.merchant_customer?.name},
        {title: "Phone", key: "ph", render: (t, r) => r?.merchant_customer?.phone_number},
        {
            title: "Collection",
            key: "debit",
            dataIndex: "debit",
            filterDropdown: ({confirm, clearFilters}) => (
                <OptFilter
                    tableFilter={tableFilter}
                    setTableFilter={setTableFilter}
                    confirm={confirm}
                    clearFilters={clearFilters}
                    setFilters={setFilters}
                    operator="operator_debit"
                    to="to_debit"
                    from="from_debit"
                />
            ),
            ...sorter,
        },
        {
            title: "Lent",
            key: "credit",
            dataIndex: "credit",
            filterDropdown: ({confirm, clearFilters}) => (
                <OptFilter
                    tableFilter={tableFilter}
                    setTableFilter={setTableFilter}
                    confirm={confirm}
                    clearFilters={clearFilters}
                    setFilters={setFilters}
                    operator="operator_credit"
                    to="to_credit"
                    from="from_credit"
                />
            ),
            ...sorter,
        },
        {
            title: "Transaction Date",
            dataIndex: "transaction_date",
            key: "transaction_date",
            render: (text, {transaction_date}) =>
                transaction_date && dayjs(transaction_date).format("YYYY-MM-DD"),
            ...sorter,
        },
        {
            title: "Comment", dataIndex: "comments", key: "comments", render: (comments) => comments ?
                <ModalButton
                    name={"Show"}
                    title={"Comment"}
                    data={comments}
                /> : 'N/A',
        },
        {title: "Created", dataIndex: "created_at", key: "created_at", ...sorter},
    ];

    const filterParams = [
        {id: 1, type: "TEXT", label: "Store UID", name: "store_uid"},
        {id: 2, type: "TEXT", label: "Store ID", name: "store_strid"},
        {id: 3, type: "TEXT", label: "Store Name", name: "store_name"},
        {id: 4, type: "DATERANGE", label: "Date Range", name: "date_range"},
        {id: 5, type: "TEXT", label: "Customer Name", name: "customer_name"},
        {id: 6, type: "TEXT", label: "Customer Phone", name: "customer_phone"},
        {id: 7, type: "DATE", label: "Transaction Date", name: "date"},
    ];

    //render
    return (
        <DataLayout
            title="Haalkhata Transactions"
            columns={columns}
            filterParams={filterParams}
            filters={filters}
            setFilters={setFilters}
            setTableFilter={setTableFilter}
            apiEndpoint={apiList?.haalkhataTransactions}
            responseSlug="transaction_list"
            dateFilterName="transaction_date"
        />
    );
};

export default HaalkhataTransactions;
