import React, { useState } from "react";
import { Button, notification, Badge, Table } from "antd";
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import DataLayout from "../../../components/DataLayout";
import { apiList } from "../../../utils/api-list";
import OptFilter from "../../../components/OperatorFilter";
import ModalButton from "../../../components/ModalButton";
import { postData } from "../../../utils/api-service";

const Loan = () => {
    const [loadingApproval, setLoadingApproval] = useState({});
    const [loadingRejection, setLoadingRejection] = useState({});
    const [filters, setFilters] = useState({});
    const [tableFilter, setTableFilter] = useState();
    const [forceUpdate, setForceUpdate] = useState(0);
    const sorter = { sorter: true, sortDirections: ["descend", "ascend"] };

    const handleSubmit = async (record, action) => {
        const statusType = action == 'approve' ? 2 : 4;
        statusChangeLoader(record.key, statusType, true);
        try {
            const res = await postData({
                query: apiList?.loanStatusChange,
                data: {
                    'loan_application_id': record.id,
                    'status': statusType
                }
            });
            if (res?.data?.code === 200) {
                setForceUpdate((prev) => prev + 1);
                notification?.success({ message: res.data.message[0] });
            } else {
                notification?.error({ message: res.data.message[0] });
            }
        } catch (error) {
            console.error('Error during API call:', error);
            notification?.error({ message: "System Error" });
        }
        statusChangeLoader(record.key, statusType, false);
    };

    const statusChangeLoader = (recordKey = null, statusType = null, isActive = false) => {
        if (statusType == 2) {
            setLoadingApproval((prevLoadingApproval) => ({
                ...prevLoadingApproval,
                [recordKey]: isActive,
            }));
        } else {
            setLoadingRejection((prevLoadingRejection) => ({
                ...prevLoadingRejection,
                [recordKey]: isActive,
            }));
        }
    }

    const columns = [
        { title: "Store Name", dataIndex: "store_name", key: "name", render: (name) => name ? name : 'N/A' },
        {
            title: "Store ID",
            key: "store_strid",
            dataIndex: "store_strid",
            render: (store_strid) => store_strid ? store_strid : 'N/A'
        },

        {
            title: "Amount",
            key: "requested_amount",
            dataIndex: "requested_amount",
            render: (requested_amount) => requested_amount ? requested_amount : 'N/A',
            filterDropdown: ({ confirm, clearFilters }) => (
                <OptFilter
                    tableFilter={tableFilter}
                    setTableFilter={setTableFilter}
                    confirm={confirm}
                    clearFilters={clearFilters}
                    setFilters={setFilters}
                    operator="operator"
                    to="to_requested_amount"
                    from="from_requested_amount"
                />
            ),
            ...sorter,
        },

        {
            title: "Due Amount",
            key: "due_amount",
            dataIndex: "due_amount",
            ...sorter
        },

        {
            title: "Paid Amount",
            key: "paid_amount",
            dataIndex: "paid_amount",
            ...sorter
        },

        {
            title: "Disbursement Date",
            key: "disbursement_date",
            dataIndex: "disbursement_date",
            render: (disbursement_date) => disbursement_date ? disbursement_date : 'N/A',
            ...sorter
        },

        {
            title: 'Status',
            key: 'status_text',
            dataIndex: 'status_text',
            render: (status_text, record) => (
                <Badge
                    status={
                        record.status === 1
                            ? 'processing'
                            : record.status === 2
                                ? 'success'
                                : record.status === 3
                                    ? 'default'
                                    : 'error'
                    }
                    text={status_text}
                />
            ),
        },

        {
            title: "Last Updated",
            dataIndex: "created_at",
            key: "created_at",
            ...sorter
        },

        {
            title: "Details",
            key: "details",
            dataIndex: "details",
            render: (details, data) =>
                <ModalButton
                    name={"Show"}
                    title={"Details"}
                    data={
                        <>
                            <p><strong>Repayment in month: </strong> {data.repayment_in_month}</p>
                            <p><strong>Advance Tenure: </strong> {data.loan_tenure}</p>
                            <p><strong>Transaction Reference: </strong> {data.transaction_ref}</p>
                            <p><strong>Next Payment Date: </strong> {data.next_payment_date}</p>
                            <p><strong>Remarks: </strong> {data.remarks}</p>
                            {data.repayments.length > 0 && (
                                <div>
                                    <p><strong>Payment Details: </strong></p>
                                    <Table
                                        dataSource={data.repayments.map((repayment, index) => ({
                                            key: index,
                                            amount: repayment.amount,
                                            transaction_ref: repayment.transaction_ref,
                                            created_at: repayment.created_at
                                        }))}
                                        columns={[
                                            {
                                                title: 'Amount',
                                                dataIndex: 'amount',
                                                key: 'amount',
                                            },
                                            {
                                                title: 'Trans. Ref.',
                                                dataIndex: 'transaction_ref',
                                                key: 'transaction_ref',
                                            },
                                            {
                                                title: 'Date',
                                                dataIndex: 'created_at',
                                                key: 'created_at',
                                            }
                                        ]}
                                        pagination={false}
                                    />
                                </div>
                            )}



                        </>
                    }
                />
        },

        {
            title: 'Action',
            key: 'action',
            render: (record) => {
                if (record.status === 1) {
                    return (
                        <div style={{ display: 'flex' }}>
                            <Button
                                title="Approve"
                                loading={loadingApproval[record.key]}
                                icon={<CheckCircleOutlined />}
                                type="primary"
                                style={{ marginRight: 8, flex: 1, backgroundColor: 'green', borderColor: 'green', color: 'white' }}
                                onClick={() => handleSubmit(record, 'approve')}
                            />
                            <Button
                                loading={loadingRejection[record.key]}
                                icon={<CloseCircleOutlined />}
                                type="danger"
                                style={{ flex: 1, backgroundColor: 'red', borderColor: 'red', color: 'white' }}
                                onClick={() => handleSubmit(record, 'reject')}
                            />
                        </div>
                    );
                }
            },
        },
    ];

    const filterParams = [
        { id: 2, type: "TEXT", label: "Store ID", name: "store_strid" },
        { id: 3, type: "TEXT", label: "Store Name", name: "store_name" },
        { id: 4, type: "DATERANGE", label: "Date Range", name: "date_range" },
    ];

    return (
        <DataLayout
            key={forceUpdate}
            title="Advance List"
            columns={columns}
            filterParams={filterParams}
            filters={filters}
            setFilters={setFilters}
            setTableFilter={setTableFilter}
            apiEndpoint={apiList?.loanList}
            responseSlug="loan_list"
            dateFilterName="updated_at"
        />
    );
};

export default Loan;