import React, {useState} from "react";
import DataLayout from "../../../components/DataLayout";
import OptFilter from "../../../components/OperatorFilter";
import {apiList} from "../../../utils/api-list";
import {pay_methods, sslcom_opts, sts} from "./topup.helper";
import ModalButton from "../../../components/ModalButton";

const operators = [
    {key: 1, value: 1, title: "Grameenphone"},
    {key: 2, value: 2, title: "Banglalink"},
    {key: 3, value: 3, title: "Robi"},
    {key: 4, value: 6, title: "Airtel"},
    {key: 5, value: 5, title: "Teletalk"},
];

const TopUp = () => {
    const [filters, setFilters] = useState({});
    const [tableFilter, setTableFilter] = useState();
    const sorter = {sorter: true, sortDirections: ["descend", "ascend"]};
    const columns = [
        // { title: "Store UID", dataIndex: "store_uid", key: "id" },
        {title: "Store Name", dataIndex: "store_name", key: "name", render: (name) => name ? name : 'N/A'},
        {
            title: "Store ID",
            key: "store_strid",
            dataIndex: "store_strid",
            render: (store_strid) => store_strid ? store_strid : 'N/A'
        },
        {
            title: "Msisdn", key: "msisdn", render: (row) =>
                <>
                    {row.msisdn}
                    {row.operator_title ? <br/> : ''}
                    {row.operator_title}
                    {row.connection_type ? (' (' + row.connection_type + ')') : ''}
                </>
        },
        {
            title: "Amount",
            key: "amount",
            dataIndex: "amount",
            render: (amount) => amount ? amount : 'N/A',
            filterDropdown: ({confirm, clearFilters}) => (
                <OptFilter
                    tableFilter={tableFilter}
                    setTableFilter={setTableFilter}
                    confirm={confirm}
                    clearFilters={clearFilters}
                    setFilters={setFilters}
                    operator="operator"
                    to="to_amount"
                    from="from_amount"
                />
            ),
            ...sorter,
        },
        {
            title: "Bill Amount", key: "bill_amount", dataIndex: "bill_amount",
            render: (bill_amount) => bill_amount ? bill_amount : 'N/A',
            ...sorter
        },
        {title: "Discount", key: "discount", dataIndex: "discount"},
        {title: "Payment method", key: "pm", dataIndex: "payment_method"},
        {title: "Status", key: "status_title", dataIndex: "status_title"},
        {title: "Transaction date", key: "tr_d", dataIndex: "transaction_date"},
        {title: "Created", key: "c_at", dataIndex: "created_at", ...sorter},
        {
            title: "Details",
            key: "details",
            dataIndex: "details",
            render: (details, data) =>
                <ModalButton
                    name={"Show"}
                    title={"Details"}
                    data={
                        <>
                            <p><strong>Deducted amount: </strong> {data.deducted_amount}</p>
                            <p><strong>GUID: </strong> {data.guid}</p>
                            <p><strong>VR GUID: </strong> {data.vr_guid}</p>
                            <p><strong>Transaction ID: </strong> {data.transaction_id}</p>
                            <p><strong>Query result: </strong> {data.query_result}</p>
                            <p><strong>SSLCommerz Transaction ID: </strong> {data.ssl_commerz_tran_id}</p>
                            <p><strong>SSLCommerz status: </strong> {data.ssl_commerz_status_string}</p>
                            <p><strong>Remarks: </strong> {data.remarks}</p>
                        </>
                    }
                />
        }
    ];

    const filterParams = [
        {id: 1, type: "TEXT", label: "Store UID", name: "store_uid"},
        {id: 2, type: "TEXT", label: "Store ID", name: "store_strid"},
        {id: 3, type: "TEXT", label: "Store Name", name: "store_name"},
        {id: 4, type: "DATERANGE", label: "Date Range", name: "date_range"},
        {id: 5, type: "TEXT", label: "Msisdn", name: "msisdn"},
        {
            id: 6,
            type: "SELECT",
            label: "Payment method",
            name: "payment_method",
            options: pay_methods,
        },
        {id: 7, type: "DATE", label: "Transaction Date", name: "date"},
        {id: 8, type: "SELECT", label: "Status", name: "status", options: sts},
        {
            id: 9,
            type: "SELECT",
            label: "SSLCommerz status",
            name: "ssl_commerz_status",
            options: sslcom_opts,
        },
        {
            id: 10,
            type: "SELECT",
            label: "Operator",
            name: "operator_id",
            options: operators,
        },
    ];

    //render
    return (
        <DataLayout
            title="Vr Transaction List"
            columns={columns}
            filterParams={filterParams}
            filters={filters}
            setFilters={setFilters}
            setTableFilter={setTableFilter}
            apiEndpoint={apiList?.vrTransactionList}
            responseSlug="vr_transaction_list"
            dateFilterName="transaction_date"
        />
    );
};

export default TopUp;
