import React, {useState} from "react";
import DataLayout from "../../../components/DataLayout";
import OptFilter from "../../../components/OperatorFilter";
import {apiList} from "../../../utils/api-list";

const statuses = [
    {key: 0, value: 0, title: "Cancel"},
    {key: 1, value: 1, title: "Processing"},
    {key: 2, value: 2, title: "Created"},
    {key: 3, value: 3, title: "Initiated"},
    {key: 4, value: 4, title: "Success"},
    {key: 5, value: 5, title: "Failed"},
    {key: 6, value: 6, title: "Completed"},
];

const UtilityTransaction = () => {
    const [filters, setFilters] = useState({});
    const [tableFilter, setTableFilter] = useState();
    const sorter = {sorter: true, sortDirections: ["descend", "ascend"]};
    const columns = [
        {title: "Store UID", dataIndex: "store_uid", key: "id"},
        {title: "Store Name", dataIndex: "store_name", key: "name"},
        {title: "Store ID", key: "store_strid", dataIndex: "store_strid"},
        {title: "Transaction date", key: "t_dt", dataIndex: "transaction_date"},
        {title: "Transaction ID", key: "t_id", dataIndex: "transaction_id"},
        {
            title: "Amount",
            key: "amount",
            dataIndex: "amount",
            filterDropdown: ({confirm, clearFilters}) => (
                <OptFilter
                    tableFilter={tableFilter}
                    setTableFilter={setTableFilter}
                    confirm={confirm}
                    clearFilters={clearFilters}
                    setFilters={setFilters}
                    operator="operator"
                    to="to_amount"
                    from="from_amount"
                />
            ),
            ...sorter,
        },
        {title: "Bill Amount", key: "bill_amount", dataIndex: "bill_amount"},
        {title: "SSLCommerz tran ID", key: "s", dataIndex: "ssl_commerz_tran_id"},
        {title: "Status", key: "status_string", dataIndex: "status_string"},
        {
            title: "SSLCommerz status",
            key: "ssl_commerz_status_string",
            dataIndex: "ssl_commerz_status_string",
        },
        {
            title: "Common Api Status",
            key: "common_api_status_string",
            dataIndex: "common_api_status_string",
        },
        {title: "Common Api LID", key: "c_api", dataIndex: "common_api_lid"},
        {title: "Payment method", key: "pm", dataIndex: "payment_method"},
        {title: "Utility Title", key: "title", dataIndex: "title"},
        {title: "Discount", key: "discount", dataIndex: "discount"},
        {title: "Deducted amount", key: "ded_am", dataIndex: "deducted_amount"},
        {title: "Created", key: "created_at", dataIndex: "created_at", ...sorter},
        {title: "Remark", key: "remarks", dataIndex: "remarks"},
    ];

    const filterParams = [
        {id: 1, type: "TEXT", label: "Store UID", name: "store_uid"},
        {id: 2, type: "TEXT", label: "Store ID", name: "store_strid"},
        {id: 3, type: "TEXT", label: "Store Name", name: "store_name"},
        {id: 4, type: "DATERANGE", label: "Date Range", name: "date_range"},
        {id: 5, type: "TEXT", label: "Msisdn", name: "msisdn"},
        {id: 6, type: "TEXT", label: "Payment method", name: "payment_method"},
        {id: 7, type: "DATE", label: "Transaction Date", name: "date"},
        {
            id: 8,
            type: "SELECT",
            label: "Status",
            name: "status",
            options: statuses,
        },
    ];

    //render
    return (
        <DataLayout
            title="Utility Transaction List"
            columns={columns}
            filterParams={filterParams}
            filters={filters}
            setFilters={setFilters}
            setTableFilter={setTableFilter}
            apiEndpoint={apiList?.utilityTransaction}
            responseSlug="transactions"
            dateFilterName="transaction_date"
        />
    );
};

export default UtilityTransaction;
