import React, {useState} from "react";
import DataLayout from "../../../components/DataLayout";
import {apiList} from "../../../utils/api-list";
import OptFilter from "../../../components/OperatorFilter";

const Store = () => {
    const [filters, setFilters] = useState({});
    const [tableFilter, setTableFilter] = useState();
    const sorter = {sorter: true, sortDirections: ["descend", "ascend"]};

    const columns = [
        // { title: "Store UID", dataIndex: "store_uid", key: "id" },
        {title: "Store Name", dataIndex: "store_name", key: "name", render: (name) => name ? name : 'N/A'},
        {
            title: "Store ID",
            key: "store_strid",
            dataIndex: "store_strid",
            render: (store_strid) => store_strid ? store_strid : 'N/A'
        },
        {
            title: "Total Sale Count",
            dataIndex: "sales_count",
            key: "sales_count",
            filterDropdown: ({confirm, clearFilters}) => (
                <OptFilter
                    tableFilter={tableFilter}
                    setTableFilter={setTableFilter}
                    confirm={confirm}
                    clearFilters={clearFilters}
                    setFilters={setFilters}
                    operator="operator_sales_count"
                    to="to_sales_count"
                    from="from_sales_count"
                />
            ),
            ...sorter,
        },
        {
            title: "Total Sale Amount",
            dataIndex: "sales_sum_total_amount",
            key: "sales_sum_total_amount",
            render: (sales_sum_total_amount) => sales_sum_total_amount ? sales_sum_total_amount : 0,
            filterDropdown: ({confirm, clearFilters}) => (
                <OptFilter
                    tableFilter={tableFilter}
                    setTableFilter={setTableFilter}
                    confirm={confirm}
                    clearFilters={clearFilters}
                    setFilters={setFilters}
                    operator="operator_sales_sum_total_amount"
                    to="to_sales_sum_total_amount"
                    from="from_sales_sum_total_amount"
                />
            ),

            ...sorter,
        },
        {
            title: "Last Transaction Date",
            dataIndex: "last_transaction_date",
            key: "last_transaction_date",
            ...sorter,
        },
        {title: "Created", dataIndex: "created_at", key: "created_at", ...sorter},
    ];

    const filterParams = [
        {id: 1, type: "TEXT", label: "Store UID", name: "store_uid"},
        {id: 2, type: "TEXT", label: "Store ID", name: "store_strid"},
        {id: 3, type: "TEXT", label: "Store Name", name: "store_name"},
        {id: 4, type: "DATERANGE", label: "Date Range", name: "date_range"},
    ];

    //render
    return (
        <DataLayout
            title="Store List"
            columns={columns}
            filterParams={filterParams}
            filters={filters}
            setFilters={setFilters}
            setTableFilter={setTableFilter}
            apiEndpoint={apiList?.storeList}
            responseSlug="store_list"
            dateFilterName="last_transaction_date"
        />
    );
};

export default Store;
