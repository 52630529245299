export const subs_prov_drp = [
    {key: 1, value: 1, title: "Rabbithole"},
    {key: 2, value: 2, title: "BongoBD"},
];

export const sts = [
    {key: 0, value: 0, title: "Created"},
    {key: 1, value: 1, title: "Success"},
    {key: 2, value: 2, title: "Failed"},
    {key: 3, value: 3, title: "Cancel"},
    {key: 4, value: 4, title: "Coupon Shortage"},
    {key: 5, value: 5, title: "Coupon Decryption Failed"},
    {key: 6, value: 6, title: "Failed to Send SMS"}
];

export const sslcom_opts = [
    {key: 0, value: 0, title: "Initiate"},
    {key: 1, value: 1, title: "Success"},
    {key: 2, value: 2, title: "Failed"},
    {key: 3, value: 3, title: "No Response"},
    {key: 4, value: 4, title: "Cancel"},
    {key: 5, value: 5, title: "Verify"},
    {key: 6, value: 6, title: "Pending"}
];