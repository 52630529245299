export const service_sub_cat_drp = [
    {key: 1, value: 1, title: "Electricity"},
    {key: 2, value: 2, title: "Gas"},
    {key: 3, value: 3, title: "Water"},
    {key: 4, value: 4, title: "Internet"},
];

export const service_prov_drp = [
    {key: 1, value: 1, title: "BREB (PREPAID)"},
    {key: 2, value: 2, title: "BPDB (PREPAID)"}
];

export const sts = [
    {key: 0, value: 0, title: "Initiated"},
    {key: 1, value: 1, title: "Pending"},
    {key: 2, value: 2, title: "Received Callback"},
    {key: 3, value: 3, title: "Success"},
    {key: 4, value: 4, title: "Failed"}
];