import {Button, Card, Form, Input, notification} from "antd";
import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {logout} from "../../../store/actions";
import {apiList} from "../../../utils/api-list";
import {putData} from "../../../utils/api-service";

const ChangePassword = () => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState();
    const rules = {rules: [{required: true, message: "Required field!"}]};
    const submit = async (values) => {
        setLoading(true);
        const res = await putData({
            query: apiList?.changePassword,
            data: values,
        });
        if (res?.data?.code !== 200) {
            notification?.error({message: res?.data?.message});
        } else {
            dispatch(logout());
        }
        setLoading(false);
    };

    return (
        <div style={{width: '500px', margin: '50px auto'}}>
            <Card className="elevated card">
                <h2>Change Password</h2>
                <Form onFinish={submit} layout="vertical" form={form}>

                    <Form.Item {...rules} label="Password" name="old_password">
                        <Input placeholder="Old Password" type="password"/>
                    </Form.Item>

                    <Form.Item {...rules} label="New Password" name="new_password">
                        <Input placeholder="New Password" type="password"/>
                    </Form.Item>

                    <Form.Item {...rules} label="Confirm Password" name="new_password_confirmation">
                        <Input placeholder="Confirm New Password" type="password"/>
                    </Form.Item>

                    <Button
                        loading={loading}
                        htmlType="submit"
                        block
                        type="primary"
                    >
                        Change Password
                    </Button>
                </Form>
            </Card>
        </div>
    );
};

export default ChangePassword;
