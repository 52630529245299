export const apiList = {
    //auth
    login: "api/auth/api-login",
    logout: "api/auth/api-logout",
    changePassword: "api/auth/change-password",

    //haalkhata
    merchantList: "api/v1/haalkhata/reports/merchant-list",
    merchantCustomer: "api/v1/haalkhata/reports/merchant-customer",
    haalkhataTransactions: "api/v1/haalkhata/reports/haalkhata-transactions",
    loanList: "api/v1/haalkhata/dashboard/loan-list",
    loanDetails: "api/v1/haalkhata/dashboard/loan-details",
    loanStatusChange: "api/v1/haalkhata/dashboard/loan-status-change",


    //permission
    permissions: "api/v1/permission-list",
    permCreateUpdate: "api/v1/permission-create-update",

    //role
    roles: "api/v1/role-list",
    roleCreateUpdate: "api/v1/role-create-update",

    //common-api
    vrTransactionList: "api/v1/commonapi/reports/vr-transaction-list",
    utilityTransaction: "api/v1/commonapi/reports/transaction-list",
    subscriptionTransaction: "api/v1/commonapi/reports/subscription-transaction-list",
    serviceTransaction: "api/v1/commonapi/reports/service-transaction-list",

    //bechabikri
    storeList: "api/v1/bechabikri/reports/store-list",
    saleList: "api/v1/bechabikri/reports/sale-list",

    //hyperlocal
    orderList: "api/v1/hyperlocal/reports/order-list",
    dateWiseProductSaleList: "api/v1/hyperlocal/reports/date-wise-product-sale-list",

    //ocr
    requestList: "api/v1/ocr/reports/request-list",
};
