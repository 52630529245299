import {Dropdown} from "antd";
import Avatar from "antd/lib/avatar/avatar";
import React from "react";
import {useDispatch} from "react-redux";
import {logout} from "../../store/actions";
import "./header.scss";
import {Link} from "react-router-dom";

export const Header = () => {
    const dispatch = useDispatch();

    const items = [
        {
            key: '1',
            label: (
                <Link to="/user/change-password">
                    Change Password
                </Link>
            ),
        },
        {
            type: 'divider',
        },
        {
            key: '4',
            label: (
                <Link onClick={() => dispatch(logout())}>
                    Logout
                </Link>
            ),
        },
    ];

    return (
        <div className="header-wrapper">
            <div className="container header">
                <h6>Easy M Dashboard</h6>
                <Dropdown
                    menu={{
                        items,
                    }}
                    trigger={['click']}
                >
                    <Avatar shape="square" size="large" style={{cursor: 'pointer'}}>EMD</Avatar>
                </Dropdown>
            </div>
        </div>
    );
};
