import {Card, Divider, notification, Pagination, Table, Tag,} from "antd";
import React, {useEffect, useRef, useState} from "react";
import {postData} from "../../utils/api-service";
import SearchFilter from "../SearchFilter";

const DataLayout = ({
                        title,
                        filterParams,
                        columns,
                        filters,
                        setFilters,
                        setTableFilter,
                        apiEndpoint,
                        responseSlug,
                        dateFilterName,
                    }) => {
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(25);
    const [total, setTotal] = useState();
    const [list, setList] = useState();
    const [downloading, setDownloading] = useState();
    const loaded = useRef(false);
    const loaded_page = useRef(false);

    const getList = async (param) => {
        const res = await postData({
            query: apiEndpoint,
            data: param,
        });
        if (res?.data?.code === 200) {
            setTotal(res?.data?.data?.total_count);
            setPageSize(res?.data?.data?.paginator?.record_per_page);
            setList(res?.data?.data[responseSlug]);
        } else setList([]);
    };

    const download = async () => {
        setDownloading(true);
        const res = await postData({
            query: apiEndpoint,
            data: {page: page, limit: pageSize, report: 'true', ...filters},
        });
        if (res?.data?.data?.path) window?.open(res?.data?.data?.path);
        else notification?.error({message: "Something went wrong!"});
        setDownloading(false);
    };

    useEffect(() => {
        loaded_page.current = true;
        setList(null);
        getList({...filters, page: page, limit: pageSize});
    }, []);

    //retrieve list when loaded and page number changes
    useEffect(() => {
        if (loaded_page?.current) {
            setList(null);
            getList({...filters, page: page, limit: pageSize});
        } else loaded_page.current = true;
    }, [page, pageSize]);

    //retrieve list when filters added or modifies
    useEffect(() => {
        if (loaded?.current) {
            if (!filters) {
                if (list.length > 0) {
                    setTableFilter();
                    setList(null);
                    if (page === 1) getList({page: page, limit: pageSize});
                    else setPage(1);
                }
            } else if (filters?.date) {
                //🎁🎁🎁 Optimization possible
                let data = {...filters, [dateFilterName]: filters?.date};
                delete data?.date;
                setFilters(data);
            } else {
                setList(null);
                if (page === 1) getList({...filters, page: page, limit: pageSize});
                else setPage(1);
            }
        } else loaded.current = true;
    }, [filters]);
    const tableChange = (pagination, filters, sorting) => {
        if (sorting?.order) {
            setFilters((state) => ({
                ...state,
                sort_name: sorting?.field,
                sort_type: sorting?.order === "descend" ? "desc" : "asc",
            }));
        } else {
            setFilters((state) => ({
                ...state,
                sort_name: null,
                sort_type: null,
            }));
        }
    };

    return (
        <div className="sub-module">
            <SearchFilter
                download={download}
                downloading={downloading}
                submit={setFilters}
                params={filterParams}
            />
            <Divider orientation="left">
                <h3>
                    {title}
                    {total && total !== 0 ? (
                        <Tag className="count-tag" color="#05c46b">
                            {" "}
                            {total.toLocaleString()}
                        </Tag>
                    ) : (
                        ""
                    )}
                </h3>
            </Divider>
            <Card>
                <Table
                    scroll={{x: true}}
                    size="middle"
                    pagination={false}
                    columns={columns}
                    loading={!list}
                    onChange={tableChange}
                    dataSource={list?.map((item, index) => ({key: index, ...item}))}
                />
                <div className="pagination">
                    <Pagination
                        total={total}
                        current={page}
                        onChange={(page, size) => {
                            setPageSize(size);
                            setPage(pageSize == size ? page : 1);
                        }}
                        showTotal={(total, range) =>
                            `${range[0]}-${range[1]} of ${total} items`
                        }
                        pageSizeOptions={[10, 25, 50, 100]}
                        pageSize={pageSize}
                    />
                </div>
            </Card>
        </div>
    );
};

export default DataLayout;
