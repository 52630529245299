import React, {useState} from "react";
import DataLayout from "../../../components/DataLayout";
import {apiList} from "../../../utils/api-list";
import OptFilter from "../../../components/OperatorFilter";

const DateWiseProductSaleList = () => {
    const [filters, setFilters] = useState({});
    const [tableFilter, setTableFilter] = useState();
    const sorter = {sorter: true, sortDirections: ["descend", "ascend"]};

    const columns = [
        {
            title: "Order Date",
            key: "order_date",
            dataIndex: "order_date",
            render: (order_date) => order_date ? order_date : 'N/A',
            ...sorter
        },
        {
            title: "Store Name",
            key: "store_name",
            dataIndex: "store_name",
            render: (store_name) => store_name ? store_name : 'N/A',
            ...sorter
        },
        {
            title: "Store ID",
            key: "store_strid",
            dataIndex: "store_strid",
            render: (store_strid) => store_strid ? store_strid : 'N/A'
        },
        {
            title: "Category Name",
            key: "category_name",
            dataIndex: "category_name",
            render: (category_name) => category_name ? category_name : 'N/A',
            ...sorter
        },
        {
            title: "Product Name",
            key: "product_name",
            dataIndex: "product_name",
            render: (product_name) => product_name ? product_name : 'N/A',
            ...sorter
        },
        {
            title: "Price",
            key: "price",
            dataIndex: "price",
            render: (price) => price ? price : 0,
            filterDropdown: ({confirm, clearFilters}) => (
                <OptFilter
                    tableFilter={tableFilter}
                    setTableFilter={setTableFilter}
                    confirm={confirm}
                    clearFilters={clearFilters}
                    setFilters={setFilters}
                    operator="operator"
                    to="to_price"
                    from="from_price"
                />
            ),
            ...sorter
        },
        {
            title: "Quantity",
            key: "quantity",
            dataIndex: "quantity",
            render: (quantity) => quantity ? quantity : 0,
            filterDropdown: ({confirm, clearFilters}) => (
                <OptFilter
                    tableFilter={tableFilter}
                    setTableFilter={setTableFilter}
                    confirm={confirm}
                    clearFilters={clearFilters}
                    setFilters={setFilters}
                    operator="operator"
                    to="to_quantity"
                    from="from_quantity"
                />
            ),
            ...sorter
        },
        {
            title: "Total Price",
            key: "total_price",
            dataIndex: "total_price",
            render: (total_price) => total_price ? total_price : 0,
            filterDropdown: ({confirm, clearFilters}) => (
                <OptFilter
                    tableFilter={tableFilter}
                    setTableFilter={setTableFilter}
                    confirm={confirm}
                    clearFilters={clearFilters}
                    setFilters={setFilters}
                    operator="operator"
                    to="to_total_price"
                    from="from_total_price"
                />
            ),
            ...sorter
        },
    ];

    const filterParams = [
        {id: 1, type: "TEXT", label: "Store ID", name: "store_strid"},
        {id: 2, type: "TEXT", label: "Store Name", name: "store_name"},
        {id: 3, type: "TEXT", label: "Category Name", name: "category_name"},
        {id: 4, type: "TEXT", label: "Product Name", name: "product_name"},
        {id: 5, type: "DATERANGE", label: "Order Date Range", name: "created_at"},
    ];

    //render
    return (
        <DataLayout
            title="Date Wise Product Sale List"
            columns={columns}
            filterParams={filterParams}
            filters={filters}
            setFilters={setFilters}
            setTableFilter={setTableFilter}
            apiEndpoint={apiList?.dateWiseProductSaleList}
            responseSlug="hyperlocal_date_wise_product_sale_list"
            dateFilterName="created_at"
        />
    );
};

export default DateWiseProductSaleList;
