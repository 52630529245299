import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import {mainRoutes} from "../routes";
import {login} from "../store/actions";
import Login from "./Auth/Login";
import ErrorPage from "./Error";
import cookies from "js-cookie";

const App = () => {
    const state = useSelector((state) => state?.authReducer);
    const dispatch = useDispatch();
    useEffect(() => {
        if (cookies?.get("token")) dispatch(login(cookies?.get("token")));
    }, []);

    return state?.isLoggedIn ? <DashboardWrapper/> : <Login/>;
};

const DashboardWrapper = () => (
    <BrowserRouter>
        <Switch>
            {mainRoutes?.map((item) => (
                <Route
                    key={item?.id}
                    path={item?.path}
                    component={item?.component}
                    exact
                />
            ))}
            <Route path="*" component={ErrorPage} exact/>
        </Switch>
    </BrowserRouter>
);

export default App;
