import axios from "axios";
import {logout} from "../store/actions";
import store from "../store";
// const base_url = "https://easymdashboard.sslcommerz.com/backoffice/"; //live
// const base_url = "http://easy_m_dashbord_back_office.test/"; //laragon
// const base_url = "http://localhost:8000/"; //xampp
const base_url = "https://easymdashbaordback.publicdemo.xyz/public/"; //demo

const generateHeaders = () => ({
    Authorization: `Bearer ${store?.getState()?.authReducer?.token}`,
    lang: "en",
});

export const postData = async ({query, data, noToken}) => {
    try {
        let res = await axios({
            method: "POST",
            url: base_url + query,
            headers: !noToken && generateHeaders(),
            data: data,
        });
        if (res?.data?.code === 401) store?.dispatch(logout());
        else if (res?.data?.code === 500) return false;
        else return res;
    } catch (error) {
        console.log("HTTP ERROR   >>>>>>>>", error.response);
        if (error?.response?.status === 401) {
            store?.dispatch(logout());
        }
        return false;
    }
};

export const putData = async ({query, data, noToken}) => {
    try {
        let res = await axios({
            method: "PUT",
            url: base_url + query,
            headers: !noToken && generateHeaders(),
            data: data,
        });
        return res;
    } catch (error) {
        console.log("HTTP ERROR   >>>>>", error.response);
        if (error?.response?.status === 401) {
            //   return "logout";
        }
    }
};

// export const delData = async ({query, data, noToken}) => {
//   try {
//     let res = await axios({
//       method: "DELETE",
//       url: base_url + query,
//       headers: !noToken && generateHeaders(),
//       data: data,
//     });
//     return res;
//   } catch (error) {
//     console.log("HTTP ERROR   >>>>>", error.response);
//     // if (error?.response?.status === 401) auth?.logout();
//   }
// };
//
// export const getData = async ({query, data, noToken}) => {
//   try {
//     let res = await axios({
//       method: "GET",
//       url: base_url + query,
//       headers: !noToken && generateHeaders(),
//       params: data || {},
//     });
//     return res;
//   } catch (error) {
//     console.log("HTTP ERROR   >>>>>", error.response);
//     if (error?.response?.status === 401) {
//       //   return "logout";
//     }
//   }
// };
