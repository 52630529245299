import cookies from "js-cookie";

const initial = {isLoggedIn: false, token: null, user: null};

export const authReducer = (state = initial, action) => {
    switch (action.type) {
        case "LOGIN":
            return {
                ...state,
                isLoggedIn: true,
                token: action?.value,
            };
        case "LOGOUT":
            cookies?.remove("token");
            cookies?.remove("user");
            return {
                ...state,
                isLoggedIn: false,
                token: null,
                user: null,
            };
        default:
            return state;
    }
};
