import {Button, Form, Input, notification, Pagination, Skeleton, Table,} from "antd";
import React, {useEffect, useState} from "react";
import {apiList} from "../../../utils/api-list";
import {postData} from "../../../utils/api-service";
import Modal from "antd/lib/modal/Modal";

const Roles = () => {
    const [form] = Form.useForm();
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [submitLoading, setSubmitLoading] = useState();
    const [total, setTotal] = useState();
    const [list, setList] = useState();
    const [modal, setModal] = useState();
    const rules = {rules: [{required: true, message: "Required!"}]};
    const getList = async (param) => {
        const res = await postData({
            query: apiList?.roles,
            data: param,
        });
        if (res?.data?.code === 200) {
            setTotal(res?.data?.data?.total_count);
            setPageSize(res?.data?.data?.paginator?.record_per_page);
            setList(res?.data?.data?.roles);
        }
    };

    const submit = async (values) => {
        setSubmitLoading(true);
        let data = {...values};
        if (modal && modal !== "Create") {
            data.id = modal?.id;
        }
        const res = await postData({
            query: apiList?.roleCreateUpdate,
            data: data,
        });
        if (res?.data?.code === 200) {
            setModal(false);
            getList({page: page});
        } else {
            notification?.error({message: "Failed!"});
        }
        setSubmitLoading(false);
    };

    //retrieve list when loaded and page number changes
    useEffect(() => {
        setList(null);
        getList({page: page});
    }, [page]);

    useEffect(() => {
        if (modal && modal !== "Create") {
            form.setFieldsValue(modal);
        } else {
            form.resetFields();
        }
    }, [modal]);

    const columns = [
        {title: "Name", dataIndex: "name", key: "name"},
        {
            title: "Permissions",
            key: "perms",
            render: (t, record) => {
                return record?.role_has_permission
                    ?.map((perm) => {
                        return perm?.permission?.name;
                    })
                    .join(", ");
            },
        },
        {
            title: "Edit",
            key: "edit",
            render: (t, record) => (
                <Button type="primary" onClick={() => setModal(record)}>
                    Edit
                </Button>
            ),
        },
    ];

    return (
        <div className="sub-module">
            <div className="space window-header">
                <h3>Roles List</h3>
                <Button
                    onClick={() => setModal("Create")}
                    type="primary"
                >
                    + Create
                </Button>
            </div>
            {list ? (
                <Table
                    pagination={false}
                    columns={columns}
                    dataSource={list?.map((item, index) => ({key: index, ...item}))}
                />
            ) : (
                <Skeleton className="space" active/>
            )}
            <div className="pagination">
                <Pagination
                    showSizeChanger={false}
                    onChange={(value) => setPage(value)}
                    total={total}
                    current={page}
                    pageSize={pageSize}
                />
            </div>
            <Modal
                title={`${modal === "Create" ? "Create" : "Edit"} role`}
                centered
                visible={modal}
                onCancel={() => setModal(false)}
                footer={false}
            >
                <Form form={form} onFinish={submit} layout="vertical">
                    <Form.Item {...rules} label="Name" name="name">
                        <Input placeholder="Name"/>
                    </Form.Item>
                    {/* <Form.Item {...rules} label="Display Name" name="display_name">
            <Input placeholder="Name" />
          </Form.Item> */}
                    <Button
                        loading={submitLoading}
                        type="primary"
                        block
                        htmlType="submit"
                    >
                        Submit
                    </Button>
                </Form>
            </Modal>
        </div>
    );
};

export default Roles;
