import React, {useState} from "react";
import DataLayout from "../../../components/DataLayout";
import {apiList} from "../../../utils/api-list";
import ModalButton from "../../../components/ModalButton";

const failedData = [
    {key: 1, value: 'parse_failed', title: "Parse Failed"},
    {key: 2, value: 'nid_failed', title: "NID Failed"},
    {key: 3, value: 'dob_failed', title: "Date of Birth Failed"},
    {key: 4, value: 'name_failed', title: "Name Failed"}
];

const UserRequest = () => {
    const [filters, setFilters] = useState({});
    const sorter = {sorter: true, sortDirections: ["descend", "ascend"]};

    const columns = [
        {title: "Device ID", key: "device_id", dataIndex: "device_id"},
        {title: "User IP", key: "user_ip", dataIndex: "user_ip"},
        {title: "NID", key: "nid", dataIndex: "nid", render: (nid) => nid ? nid : 'N/A'},
        {title: "Name", key: "name", dataIndex: "name", render: (name) => name ? name : 'N/A'},
        {title: "Date of Birth", key: "dob", dataIndex: "dob", render: (dob) => dob ? dob : 'N/A'},
        {
            title: "NID Data",
            key: "nid_data",
            dataIndex: ["nid_front_raw_data", "nid_parse_data"],
            render: (nid_data, raw) => (raw['nid_front_raw_data'] || raw['nid_parse_data']) ?
                <ModalButton
                    name={"Show"}
                    title={"NID Data"}
                    data={
                        <>
                            <p><strong>NID Front Raw Data:</strong> {raw['nid_front_raw_data']} </p>
                            <p><strong>NID Parse Raw Data:</strong> {raw['nid_parse_data']} </p>
                        </>
                    }
                /> : 'N/A'
        },
        {title: "Created", key: "created_at", dataIndex: "created_at", ...sorter},
    ];

    const filterParams = [
        {id: 1, type: "TEXT", label: "Device ID", name: "device_id"},
        {id: 2, type: "TEXT", label: "User IP", name: "user_ip"},
        {id: 3, type: "TEXT", label: "NID", name: "nid"},
        {id: 4, type: "TEXT", label: "Name", name: "name"},
        {id: 5, type: "DATE", label: "Date of Birth", name: "dob"},
        {
            id: 6,
            type: "SELECT",
            label: "Failed Data",
            name: "failed_data",
            options: failedData,
        },
    ];

    //render
    return (
        <DataLayout
            title="User Request List"
            columns={columns}
            filterParams={filterParams}
            filters={filters}
            setFilters={setFilters}
            apiEndpoint={apiList?.requestList}
            responseSlug="user_request_lists"
        />
    );
};

export default UserRequest;
