import Dashboard from "../pages/Dashboard";
import ErrorPage from "../pages/Error";
import Merchant from "../pages/Haalkhata/Merchant";
import MerchantCus from "../pages/Haalkhata/MerchantCus";
import HaalkhataTransactions from "../pages/Haalkhata/Transactions";
import TopUp from "../pages/ServiceSale/TopUp";
import SubscriptionTransaction from "../pages/ServiceSale/Subscription";
import ServiceTransaction from "../pages/ServiceSale/ServiceUtility";
import UtilityTransaction from "../pages/ServiceSale/Utility";
import Permissions from "../pages/User/Permissions";
import Roles from "../pages/User/Roles";
import BechabikriStore from "../pages/Bechabikri/Store";
import HyperlocalOrderList from "../pages/Hyperlocal/OrderList";
import BechabikriSale from "../pages/Bechabikri/Sale";
import OcrUserRequest from "../pages/OCR/UserRequest";
import ChangePassword from "../pages/Auth/Login/ChangePassword";
import HyperlocalDateWiseProductSaleList from "../pages/Hyperlocal/HyperlocalDateWiseProductSaleList";
import Loan from "../pages/Haalkhata/Loan";

export const mainRoutes = [
    {id: 1, path: "/", component: Dashboard},
    {id: 2, path: "/:first", component: Dashboard},
    {id: 3, path: "/:first/:second", component: Dashboard},
];

export const dashRoutes = [
    {id: 1, path: "/haalkhata", component: ErrorPage},
    {id: 2, path: "/haalkhata/merchant", component: Merchant},
    {id: 3, path: "/haalkhata/merchant-customer", component: MerchantCus},
    {id: 4, path: "/haalkhata/transactions", component: HaalkhataTransactions},
    {id: 4, path: "/haalkhata/advance", component: Loan},
    {id: 5, path: "/commons", component: ErrorPage},
    {id: 6, path: "/commons/transaction-list", component: UtilityTransaction},
    {id: 7, path: "/commons/vr-transactions", component: TopUp},
    {id: 8, path: "/role-permissions/permissions", component: Permissions},
    {id: 9, path: "/role-permissions/roles", component: Roles},
    {id: 10, path: "/bechabikri", component: ErrorPage},
    {id: 11, path: "/bechabikri/store", component: BechabikriStore},
    {id: 12, path: "/bechabikri/sale", component: BechabikriSale},
    {id: 15, path: "/easy-grocery/order-list", component: HyperlocalOrderList},
    {id: 16, path: "/easy-grocery/date-wise-product-sale-list", component: HyperlocalDateWiseProductSaleList},
    {id: 13, path: "/ocr/user-request", component: OcrUserRequest},
    {id: 14, path: "/user/change-password", component: ChangePassword},
    {id: 15, path: "/commons/subscription-transactions", component: SubscriptionTransaction},
    {id: 15, path: "/commons/service-transactions", component: ServiceTransaction},
];
