export const sts = [
    {key: 0, value: 0, title: "Cancel"},
    {key: 1, value: 1, title: "Processing"},
    {key: 2, value: 2, title: "Created"},
    {key: 3, value: 3, title: "Initiated"},
    {key: 4, value: 4, title: "Success"},
    {key: 5, value: 5, title: "Failed"},
    {key: 6, value: 6, title: "Completed"},
];
export const pay_methods = [
    {key: 0, value: "Online", title: "Online"},
    {key: 1, value: "Balance", title: "Balance"},
];
export const sslcom_opts = [
    {key: 0, value: 0, title: "Initiated"},
    {key: 1, value: 1, title: "Success"},
    {key: 2, value: 2, title: "Failed"},
    {key: 3, value: 3, title: "No Response"},
    {key: 4, value: 4, title: "Cancel"},
    {key: 5, value: 5, title: "Verified"},
];
