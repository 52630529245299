import React, {useState} from "react";
import DataLayout from "../../../components/DataLayout";
import OptFilter from "../../../components/OperatorFilter";
import {apiList} from "../../../utils/api-list";
import {service_sub_cat_drp, service_prov_drp, sts} from "./utility.helper";
import ModalButton from "../../../components/ModalButton";

const ServiceUtility = () => {
    const [filters, setFilters] = useState({});
    const [tableFilter, setTableFilter] = useState();
    const sorter = {sorter: true, sortDirections: ["descend", "ascend"]};
    const columns = [
        {
            title: "Store Name", 
            dataIndex: "store_name", 
            key: "name", render: (name) => name ? name : 'N/A',
            ...sorter
        },
        {
            title: "Store ID",
            key: "store_strid",
            dataIndex: "store_strid",
            render: (store_strid) => store_strid ? store_strid : 'N/A'
        },
        {
            title: "Msisdn",
            key: "msisdn",
            dataIndex: "msisdn",
            render: (msisdn) => msisdn ? msisdn : 'N/A',
            ...sorter
        },
        {
            title: "Service Sub Category",
            key: "service_sub_category_title",
            dataIndex: "service_sub_category_title",
            render: (service_sub_category_title) => service_sub_category_title ? service_sub_category_title : 'N/A',
            ...sorter
        },
        {
            title: "Service Provider",
            key: "service_provider_title",
            dataIndex: "service_provider_title",
            render: (service_provider_title) => service_provider_title ? service_provider_title : 'N/A',
            ...sorter
        },
        {
            title: "Amount",
            key: "amount",
            dataIndex: "amount",
            filterDropdown: ({confirm, clearFilters}) => (
                <OptFilter
                    tableFilter={tableFilter}
                    setTableFilter={setTableFilter}
                    confirm={confirm}
                    clearFilters={clearFilters}
                    setFilters={setFilters}
                    operator="operator"
                    to="to_amount"
                    from="from_amount"
                />
            ),
            ...sorter,
        },
        {
            title: "Bill Amount",
            key: "bill_amount",
            dataIndex: "bill_amount",
            filterDropdown: ({confirm, clearFilters}) => (
                <OptFilter
                    tableFilter={tableFilter}
                    setTableFilter={setTableFilter}
                    confirm={confirm}
                    clearFilters={clearFilters}
                    setFilters={setFilters}
                    operator="operator"
                    to="to_bill_amount"
                    from="from_bill_amount"
                />
            ),
            ...sorter,
        },
        {
            title: "Charge Amount",
            key: "charge_amount",
            dataIndex: "charge_amount",
            filterDropdown: ({confirm, clearFilters}) => (
                <OptFilter
                    tableFilter={tableFilter}
                    setTableFilter={setTableFilter}
                    confirm={confirm}
                    clearFilters={clearFilters}
                    setFilters={setFilters}
                    operator="operator"
                    to="to_charge_amount"
                    from="from_charge_amount"
                />
            ),
            ...sorter,
        },
        {
            title: "Status", 
            key: "status_d", 
            dataIndex: "status_title",
        },
        {
            title: "Transaction date", 
            key: "tr_d", 
            dataIndex: "transaction_date",
            ...sorter
        },
        {title: "Created At", key: "c_at", dataIndex: "created_at", ...sorter},
        {
            title: "Details",
            key: "details",
            dataIndex: "details",
            render: (details, data) =>
                <ModalButton
                    name={"Show"}
                    title={"Details"}
                    data={
                        <>
                            <p><strong>Transaction Id: </strong> {data.transaction_id}</p>
                            <p><strong>Funding Source: </strong> {data.funding_source}</p>
                            <p><strong>Funding Transaction Id: </strong> {data.funding_transaction_id}</p>
                            <p><strong>Funding Transaction Status: </strong> {data.funding_transaction_status_title}</p>
                            <p><strong>Billing Source: </strong> {data.billing_source}</p>
                            <p><strong>Billing Transaction Id: </strong> {data.billing_transaction_id}</p>
                            <p><strong>Billing Transaction Status: </strong> {data.billing_transaction_status_title}</p>
                            <p><strong>Billing Source Lid: </strong> {data.billing_source_lid}</p>
                            <p><strong>Bill Info Request Data: </strong> {data.bill_info_request_data}</p>
                            <p><strong>Bill Info Response Data: </strong> {data.bill_info_response_data}</p>
                            <p><strong>Bill Payment Request Data: </strong> {data.bill_payment_request_data}</p>
                            <p><strong>Bill Payment Response Data: </strong> {data.bill_payment_response_data}</p>
                            <p><strong>Bill Status Request Data: </strong> {data.bill_status_request_data}</p>
                            <p><strong>Bill Status Response Data: </strong> {data.bill_status_response_data}</p>
                            <p><strong>Remarks: </strong> {data.remarks}</p>
                        </>
                    }
                />
        }
    ];

    const filterParams = [
         {id: 1, type: "TEXT", label: "Store Name", name: "store_name"},
         {id: 2, type: "TEXT", label: "Store ID", name: "store_strid"},
         {id: 3, type: "TEXT", label: "Msisdn", name: "msisdn"},
        {
            id: 4,
            type: "SELECT",
            label: "Service Sub Category",
            name: "service_sub_category_id",
            options: service_sub_cat_drp,
        },
        {
            id: 5,
            type: "SELECT",
            label: "Service Provider",
            name: "service_provider_id",
            options: service_prov_drp,
        },
        {
            id: 6, 
            type: "SELECT", 
            label: "Status", 
            name: "status", 
            options: sts
        },
        {id: 7, type: "DATE", label: "Transaction Date", name: "date"},
        {id: 8, type: "TEXT", label: "Billing Source Lid", name: "billing_source_lid"}
    ];

    //render
    return (
        <DataLayout
            title="Service Transaction List"
            columns={columns}
            filterParams={filterParams}
            filters={filters}
            setFilters={setFilters}
            setTableFilter={setTableFilter}
            apiEndpoint={apiList?.serviceTransaction}
            responseSlug="service_transaction_list"
            dateFilterName="transaction_date"
        />
    );
};

export default ServiceUtility;
