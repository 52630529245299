import {Button, Col, Input, Row, Select} from "antd";
import React from "react";

const options = [
    {key: 0, value: "<", title: "Less than"},
    {key: 1, value: ">", title: "Greater than"},
    {key: 2, value: "<=", title: "Less or Equal"},
    {key: 3, value: ">=", title: "Greater or Equal"},
    {key: 4, value: "=", title: "Equal"},
    {key: 5, value: "!=", title: "Not Equal"},
    {key: 6, value: "between", title: "Between"},
];

const OptFilter = ({
                       confirm,
                       clearFilters,
                       tableFilter,
                       setTableFilter,
                       setFilters,
                       operator,
                       to,
                       from,
                   }) => {
    return (
        <div className="space">
            <div>
                <Select
                    onChange={(value) =>
                        setTableFilter((state) => ({
                            ...state,
                            balance: {...state?.balance, [operator]: value},
                            [to]: value !== "between" ? null : state?.[to],
                        }))
                    }
                    value={tableFilter?.balance?.[operator] || []}
                    placeholder="operator"
                    size="small"
                    style={{width: "200px"}}
                >
                    {options?.map((item) => (
                        <Select.Option value={item?.value}>{item?.title}</Select.Option>
                    ))}
                </Select>
            </div>
            <Input
                value={tableFilter?.balance?.[from] || []}
                placeholder="From"
                type="number"
                size="small"
                onChange={(e) =>
                    setTableFilter((state) => ({
                        ...state,
                        balance: {
                            ...state?.balance,
                            [from]: e?.target?.value,
                        },
                    }))
                }
                style={{width: "200px", margin: "1rem 0"}}
            />
            {tableFilter?.balance?.[operator] === "between" && (
                <div>
                    <Input
                        value={tableFilter?.balance?.[to] || []}
                        placeholder="To"
                        type="number"
                        size="small"
                        style={{width: "200px", marginBottom: "1rem"}}
                        onChange={(e) =>
                            setTableFilter((state) => ({
                                ...state,
                                balance: {
                                    ...state?.balance,
                                    [to]: e?.target?.value,
                                },
                            }))
                        }
                    />
                </div>
            )}
            <Row gutter={12}>
                <Col span={12}>
                    <Button
                        onClick={() => {
                            clearFilters();
                            setTableFilter();
                            setFilters((state) => {
                                let data = {...state};
                                delete data?.[operator];
                                delete data?.[from];
                                delete data?.[to];
                                return data;
                            });
                        }}
                        block
                        size="small"
                    >
                        Clear
                    </Button>
                </Col>
                <Col span={12}>
                    <Button
                        disabled={
                            !tableFilter?.balance?.[operator] ||
                            !tableFilter?.balance?.[from] ||
                            (tableFilter?.balance?.[operator] === "between" &&
                                (!tableFilter?.balance?.[from] || !tableFilter?.balance?.[to]))
                        }
                        onClick={() => {
                            confirm();
                            setFilters((state) => ({
                                ...state,
                                ...tableFilter?.balance,
                            }));
                        }}
                        block
                        size="small"
                        type="primary"
                    >
                        Filter
                    </Button>
                </Col>
            </Row>
        </div>
    );
};

export default OptFilter;
