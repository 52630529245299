import {Col, Menu, Row} from "antd";
import React, {useEffect} from "react";
import {Route, Switch, useHistory} from "react-router-dom";
import {Header} from "../../components/Header";
import {dashRoutes} from "../../routes";
import ErrorPage from "../Error";
import "./dashboard.scss";

const sideMenu = [
    {
        id: "haalkhata",
        title: "Haalkhata",
        items: [
            {title: "Merchant", to: "/haalkhata/merchant"},
            {title: "Merchant Customer", to: "/haalkhata/merchant-customer"},
            {title: "Transactions", to: "/haalkhata/transactions"},
            {title: "Advance", to: "/haalkhata/advance"},
        ],
    },
    {
        id: "commons",
        title: "Service Sale",
        items: [
            {title: "Top-up", to: "/commons/vr-transactions"},
            // {title: "Utility", to: "/commons/transaction-list"},
            {title: "Subscriptions", to: "/commons/subscription-transactions"},
            {title: "Utility", to: "/commons/service-transactions"},
        ],
    },
    {
        id: "bechabikri",
        title: "Bechabikri",
        items: [
            {title: "Store", to: "/bechabikri/store"},
            {title: "Sale", to: "/bechabikri/sale"},
        ],
    },
    {
        id: "easy-grocery",
        title: "Easy Grocery",
        items: [
            {title: "Order List", to: "/easy-grocery/order-list"},
            {title: "Date Wise Product Sale List", to: "/easy-grocery/date-wise-product-sale-list"},
        ],
    },
    {
        id: "ocr",
        title: "OCR",
        items: [
            {title: "User Request", to: "/ocr/user-request"},
        ],
    },
    // {
    //   id: "role-permissions",
    //   title: "Roles/Permissions",
    //   items: [
    //     { title: "Permissions", to: "/role-permissions/permissions" },
    //     { title: "Roles", to: "/role-permissions/roles" },
    //     // { title: "Users", to: "/role-permissions/users" },
    //   ],
    // },
];

const Dashboard = ({match}) => {
    const {first, second} = match?.params;
    const router = useHistory();
    useEffect(() => {
        !second && router?.push("/haalkhata/merchant");
    }, []);

    return (
        <div>
            <Header/>
            <div className="dashboard">
                <Row>
                    <Col span={4}>
                        <Menu
                            defaultSelectedKeys={[`/${first}/${second}`]}
                            defaultOpenKeys={[first]}
                            className="menu"
                            mode="inline"
                        >
                            {sideMenu?.map(({id, title, items}) => (
                                <Menu.SubMenu className="group" key={id} title={title}>
                                    {items?.map((item) => (
                                        <Menu.Item
                                            onClick={() => router?.push(item?.to)}
                                            className="item"
                                            key={item?.to}
                                        >
                                            {item?.title}
                                        </Menu.Item>
                                    ))}
                                </Menu.SubMenu>
                            ))}
                        </Menu>
                    </Col>
                    <Col span={20}>
                        <div className="dashboard-window">
                            <Switch>
                                {dashRoutes?.map((item) => (
                                    <Route
                                        key={item?.id}
                                        path={item?.path}
                                        component={item?.component}
                                        exact
                                    />
                                ))}
                                <Route path="*" component={ErrorPage} exact/>
                            </Switch>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default Dashboard;
