import {Button, Modal} from 'antd';
import React, {useState} from 'react';

const ModalButton = (props) => {
    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    return (
        <>
            <Button size="small" onClick={showModal}>{props.name}</Button>
            <Modal title={props.title} visible={isModalVisible} footer={null} onCancel={handleCancel}>
                {props.data}
            </Modal>
        </>
    );
};

export default ModalButton;
