import {Button, Col, Collapse, DatePicker, Form, Input, Row, Select,} from "antd";
import React from "react";
import {ArrowDownOutlined} from "@ant-design/icons";
import dayjs from "dayjs";

const {Panel} = Collapse;

const SearchFilter = ({submit, params, downloading, download}) => {
    const [form] = Form.useForm();
    const getFormItem = (field) => {
        switch (field?.type) {
            case "TEXT":
                return <Input placeholder={field?.label}/>;
            case "SELECT":
                return (
                    <Select placeholder={field?.label} allowClear>
                        {field?.options?.map((opt) => (
                            <Select.Option value={opt?.value}>{opt?.title}</Select.Option>
                        ))}
                    </Select>
                );
            case "DATERANGE":
                return <DatePicker.RangePicker allowClear/>;
            case "DATE":
                return (
                    <DatePicker
                        style={{width: "100%"}}
                        showToday={false}
                        allowClear
                    />
                );
            case "SELECT":
                return (
                    <Select allowClear>
                        {field?.options?.map((opt) => (
                            <Select.Option key={opt?.key} value={opt?.value}>
                                {opt?.title}
                            </Select.Option>
                        ))}
                    </Select>
                );
        }
    };

    return (
        <Collapse size="small">
            <Panel header="Filters" key="1">
                <div style={{padding: '1rem 0'}}>
                    <Form
                        className="side-space"
                        layout="vertical"
                        form={form}
                        onFinish={(values) => {
                            let data = {...values};
                            if (values?.date_range) {
                                data.from_date = dayjs(values?.date_range[0]).format("YYYY-MM-DD");
                                data.to_date = dayjs(values?.date_range[1]).format("YYYY-MM-DD");
                            }
                            if (values?.date)
                                data.date = dayjs(values?.date).format("YYYY-MM-DD");
                            delete data?.date_range;
                            submit(data);
                        }}
                    >
                        <Row gutter={24}>
                            {params?.map((field) => (
                                <Col span={6} key={field?.id}>
                                    <Form.Item label={field?.label} name={field?.name}>
                                        {getFormItem(field)}
                                    </Form.Item>
                                </Col>
                            ))}
                        </Row>
                        <Row gutter={32}>
                            <Col span={4}>
                                <Button
                                    block
                                    onClick={() => {
                                        form?.resetFields();
                                        submit();
                                    }}
                                >
                                    Clear Filter
                                </Button>
                            </Col>
                            <Col span={4}>
                                <Button block type="primary" htmlType="submit">
                                    Filter
                                </Button>
                            </Col>
                            {download && (
                                <Col span={4}>
                                    <Button
                                        loading={downloading}
                                        onClick={download}
                                        icon={<ArrowDownOutlined/>}
                                    >
                                        Export Report
                                    </Button>
                                </Col>
                            )}
                        </Row>
                    </Form>
                </div>
            </Panel>
        </Collapse>
    );
};

export default SearchFilter;
