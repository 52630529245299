import React, {useState} from "react";
import DataLayout from "../../../components/DataLayout";
import {apiList} from "../../../utils/api-list";
import OptFilter from "../../../components/OperatorFilter";
import ModalButton from "../../../components/ModalButton";

const Sale = () => {
    const [filters, setFilters] = useState({});
    const [tableFilter, setTableFilter] = useState();
    const sorter = {sorter: true, sortDirections: ["descend", "ascend"]};

    const columns = [
        // { title: "Store UID", dataIndex: "store_uid", key: "id" },
        {title: "Store Name", dataIndex: "store_name", key: "name", render: (name) => name ? name : 'N/A'},
        {
            title: "Store ID",
            key: "store_strid",
            dataIndex: "store_strid",
            render: (store_strid) => store_strid ? store_strid : 'N/A'
        },
        {
            title: "Amount",
            key: "amount",
            dataIndex: "amount",
            render: (amount) => amount ? amount : 0,
            filterDropdown: ({confirm, clearFilters}) => (
                <OptFilter
                    tableFilter={tableFilter}
                    setTableFilter={setTableFilter}
                    confirm={confirm}
                    clearFilters={clearFilters}
                    setFilters={setFilters}
                    operator="operator"
                    to="to_amount"
                    from="from_amount"
                />
            ),
            ...sorter
        },
        {
            title: "Due Amount",
            key: "due_amount",
            dataIndex: "due_amount",
            render: (due_amount) => due_amount ? due_amount : 0,
            filterDropdown: ({confirm, clearFilters}) => (
                <OptFilter
                    tableFilter={tableFilter}
                    setTableFilter={setTableFilter}
                    confirm={confirm}
                    clearFilters={clearFilters}
                    setFilters={setFilters}
                    operator="operator_due"
                    to="to_due_amount"
                    from="from_due_amount"
                />
            ),
            ...sorter
        },
        {
            title: "Payment Type",
            key: "payment_type",
            dataIndex: "payment_type",
            render: (payment_type) => payment_type ? payment_type : 'N/A'
        },
        {
            title: "Transaction Date",
            key: "transaction_date",
            dataIndex: "transaction_date",
            render: (transaction_date) => transaction_date ? transaction_date : 'N/A', ...sorter
        },
        {
            title: "Customer Name",
            key: "customer_name",
            dataIndex: "customer_name",
            render: (customer_name) => customer_name ? customer_name : 'N/A'
        },
        {
            title: "Customer Mobile No",
            key: "customer_mobile_no",
            dataIndex: "customer_mobile_no",
            render: (customer_mobile_no) => customer_mobile_no ? customer_mobile_no : 'N/A'
        },
        {
            title: "Remarks", key: "remarks", dataIndex: "remarks", render: (remarks) => remarks ?
                <ModalButton
                    name={"Show"}
                    title={"Remarks"}
                    data={remarks}
                /> : 'N/A'
        },

    ];

    const filterParams = [
        {id: 1, type: "TEXT", label: "Store UID", name: "store_uid"},
        {id: 2, type: "TEXT", label: "Store ID", name: "store_strid"},
        {id: 3, type: "TEXT", label: "Store Name", name: "store_name"},
        {id: 4, type: "DATERANGE", label: "Date Range", name: "date_range"},
        {id: 5, type: "TEXT", label: "Payment Type", name: "payment_type"},
        {id: 6, type: "TEXT", label: "Customer Name", name: "customer_name"},
        {id: 7, type: "TEXT", label: "Customer Mobile No", name: "customer_mobile_no"},
        {id: 8, type: "TEXT", label: "Remarks", name: "remarks"},
    ];

    //render
    return (
        <DataLayout
            title="Sale List"
            columns={columns}
            filterParams={filterParams}
            filters={filters}
            setFilters={setFilters}
            setTableFilter={setTableFilter}
            apiEndpoint={apiList?.saleList}
            responseSlug="sale_list"
            dateFilterName="transaction_date"
        />
    );
};

export default Sale;
