import React, {useState} from "react";
import DataLayout from "../../../components/DataLayout";
import {apiList} from "../../../utils/api-list";

const Merchant = () => {
    const [filters, setFilters] = useState({});
    const [tableFilter, setTableFilter] = useState();
    const sorter = {sorter: true, sortDirections: ["descend", "ascend"]};

    const columns = [
        // { title: "Store UID", dataIndex: "store_uid", key: "id" },
        {title: "Store Name", dataIndex: "store_name", key: "name", render: (name) => name ? name : 'N/A'},
        {
            title: "Store ID",
            key: "store_strid",
            dataIndex: "store_strid",
            render: (store_strid) => store_strid ? store_strid : 'N/A'
        },
        {
            title: "Transactions",
            dataIndex: "transactions_count",
            key: "tc",
            ...sorter,
        },
        {title: "Customers", dataIndex: "merchant_customers_count", key: "cc", ...sorter},
        {title: "Created", dataIndex: "created_at", key: "created_at", ...sorter},
    ];

    const filterParams = [
        {id: 1, type: "TEXT", label: "Store UID", name: "store_uid"},
        {id: 2, type: "TEXT", label: "Store ID", name: "store_strid"},
        {id: 3, type: "TEXT", label: "Store Name", name: "store_name"},
        {id: 4, type: "DATERANGE", label: "Date Range", name: "date_range"},
    ];

    //render
    return (
        <DataLayout
            title="Merchant List"
            columns={columns}
            filterParams={filterParams}
            filters={filters}
            setFilters={setFilters}
            setTableFilter={setTableFilter}
            apiEndpoint={apiList?.merchantList}
            responseSlug="merchant_list"
            dateFilterName="payback_date"
        />
    );
};

export default Merchant;
